@import "../../../assets/scss/variabls";
@import "../../../assets/scss/common";

.hero-section {
    text-align: center;
    padding: 20px;
    min-height: 400px;
    max-height: 700px;
    height: 50vh;
    margin-bottom: 40px;
}

.hero-section h1 {
    font-size: 2em;
    margin-bottom: 20px;
    font-family: $heading-font;
    color: inherit;
}

.centered-container {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: relative;
}

.hero-section p {
    font-size: 1em;
    margin-bottom: 20px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    font-family: $body-font;
}

.button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.btn {
    padding: 10px 20px;
    border: 1px solid $heading-color;
    text-decoration: none;
    color: $heading-color;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}