.gallery__rectangle-row {
    display: flex;
    flex-wrap: nowrap; /* Prevent wrapping, always keep three items in one row */
    gap: 16px; /* Adjust gap between items */
    justify-content: space-between; /* Distribute items evenly with gaps */
    max-height: calc(100vh - 200px);
}

.gallery__rectangle-item {
    flex-basis: calc(33.33% - 16px); /* Ensures three items per row */
    min-width: 0; /* Allows the items to shrink without breaking the layout */
    box-sizing: border-box;
    position: relative;
    aspect-ratio: 9 / 16; /* Maintains the 16:9 aspect ratio */
    overflow: hidden;
    background-color: transparent;
}

.gallery__rectangle-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the container while maintaining aspect ratio */
}
.gallery__rectangle-item-wide {
    flex: 1 1 calc(66.66% - 16px); /* Adjust the basis to control item size */
    min-width: 100px; /* Minimum width for responsive behavior */
    background-color: transparent; /* Example background color */
    padding: 0px; /* Example padding */
    box-sizing: border-box;
    max-height: 550px; // Maximum height
    padding-bottom: 16px;
}

.logo-toggle {
    width: 143px;
}

.nav-gallery-inner {
    position: relative;
    width: 100%;
    height: 100%; /* Let the container height adjust based on content */
    overflow: hidden;

    & img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        object-position: center;
        transition: transform 0.3s ease; /* Smooth zoom effect */
    }

    /*-- Content --*/
    & .nav-gallery-content {
        position: absolute;
        top: 50%;
        opacity: 1;
        visibility: visible;
        padding: 0px 0px;
        transition: all 0.3s ease 0s;
        border-radius: 5px;
        text-align: center;
        left: 50%;
        transform: translate(-50%, -50%);
        display: table-cell;
        vertical-align: middle;
        height: 50px;
        width: 100%;
        & h4 {
            font-size: calc(3vw)!important;
            font-weight: 600;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-bottom: 0 !important;
            position: relative;
            transform: translateY(-50%);
            top: 50%;
        }
        @media screen and (min-width: 900px) {
            & h4 {
                font-size: calc(1.5vw + 1rem)!important
            }
            
        }
        & span {
            display: block;
            width: 200px;
            font-size: 14px;
            line-height: 31px;
            text-transform: capitalize;
            letter-spacing: 1px;
            border-top: 1px solid $body-color;
            border-bottom: 1px solid $body-color;
            margin: auto;
        }
        
        /*-- Team Social --*/
        & .team-social {
            margin-top: 15px;
            display: flex;
            flex: 1 0 100%;
            width: 100%;
            justify-content: center;
            & a {
                margin: 0 12px;
                & i {}
                &:hover {
                    color: $heading-color;
                }
            }
        }
    }
    
    &:hover {
        & img {
            transform: translate(-50%, -50%) scale(1.1); /* Slight zoom effect */
        }
        & .team-content {
            opacity: 1;
            visibility: visible;
        }
    }
}

/* Media Query for Wide Screens */
@media (min-width: 900px) {
    .nav-gallery-inner .nav-gallery-content h4 {
        font-size: calc(1.5vw + 1rem); /* Increase the font size on wide screens */
    }

    .nav-gallery-inner .nav-gallery-content span {
        font-size: 16px; /* Adjust span font size as well */
    }
}