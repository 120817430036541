/* ParallaxBackground.css */

.parallax-section {
    width: 100%;
    height: 300px; /* Adjust the height as necessary */
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    position: relative;
    filter: grayscale(100%) brightness(50%);
  }
  
  .parallax-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the darkness as necessary */
    z-index: 1;
  }
  
  .parallax-content {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: white;
  }
  