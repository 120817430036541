.nav-gallery-content {
    color: white!important;

    & h1, & h2, & h3, & h4, & h5, & h6 {
        color: white;
      }
}

.dark-version {
    color: white;
  
    // Common Style
    & p {
      color: white;
    }
  
    & h1, & h2, & h3, & h4, & h5, & h6 {
      color: #333333;
    }
}