.carousel-item {
    width: 100%;
    height: 300px; /* Set the desired height for the images */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .carousel-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the container */
    object-position: center; /* Center the image */
  }
  