.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: white;
}

.loader-svg {
    position: relative;
    width: 450px;
    height: 450px;
}

.loader-path {
    --strokeLength: 2761.248046875;
    --strokeIncrement: 4;
    --strokeCalc: 0;
    stroke-dasharray: 2761.248046875;
    stroke-dashoffset: 0;
    transition: stroke-dashoffset 1s ease;
    animation: draw 4s ease infinite;
    fill: transparent;
    stroke: black;
    stroke-width: 15px;
}

@keyframes draw {
    to {
        stroke-dashoffset: 2761.248046875;
    }
}

// .loader-dot {
//     transform: translate(-50%, -50%);
// }
