@import "../../../assets/scss/variabls";
@import "../../../assets/scss/common";

.contact-form textarea {
    // font-family: "Poppins", sans-serif;
    display: block;
    width: 100%;
    height: 160px;
    border-width: 0 0 2px;
    border-style: solid;
    border-color: #dbdbdb;
    background-color: transparent;
    font-size: 14px;
    line-height: 1;
    // font-weight: 500;
    letter-spacing: 1px;
    padding-bottom: 12px;
    color: #555555;
    margin-bottom: 25px;
    resize: none;
}

.contact-form input {
    // font-family: "Poppins", sans-serif;
    display: block;
    width: 245px;
    border-width: 0 0 2px;
    border-style: solid;
    border-color: #dbdbdb;
    background-color: transparent;
    font-size: 14px;
    line-height: 1;
    // font-weight: 500;
    letter-spacing: 1px;
    padding-bottom: 12px;
    color: #555555;
    margin-bottom: 25px;
}

.free-trial-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  overflow: hidden;
  padding: 20px;
}

.outer-container {
    padding: 0 !important;
}

.inner-container {
    padding: 40px !important;
}

.dropdown-select {
    display: grid;
}

.grid-container {
    display: flex;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width:500px;
    left: 50%;
    position: relative;
    transform: translate(-50%, 0px);
    margin-top: 20px;
    margin-bottom: 20px;
}

.grid-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.grid-label {
    width: 250px;
}

.checkbox {
    width: 45px !important;
}

.form-row {
    display: flex;
    justify-content: space-between;
    height: 65px;
}

.date-input {
    width: 75px !important;
    margin-left: 10px;
}

.button-row {
    display: flex;
    justify-content: space-around;
}