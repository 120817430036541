
    .background-image {
        background-size: 450px;
        right: 0;
        top: 0;
        z-index: 1;
        opacity: 0.1; // Adjust opacity as needed
        background-repeat: no-repeat;
    }

    // .title {
    //     font-size: 2em;
    //     margin-bottom: 20px;
    //     font-family: "Poppins", sans-serif;
    //     color: inherit;
    //     line-height: 42px;
    //     font-weight: 400;
    // margin-top: 0;
    // }

    .left-column {
        max-width: 40%
    }

    .right-column {
        max-width:40%
    }

    .benefits-grid {
            display: flex;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;
            flex-wrap: wrap;
            justify-content: space-between;
            // max-width:500px;
            left: 50%;
            position: relative;
            transform: translate(-50%, 0px);
            margin-top: 20px;
            margin-bottom: 20px;
            min-height: 450px;
    }

    .section-title {
        max-width: 400px;
        position: relative;
        margin-top: 40px !important;
    }

    .service-item {
        height: 33%;
        text-align: center;
        padding-bottom: 40px;
    }