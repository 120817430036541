.program-gallery {
    width: 100%;
    max-height: calc(100vh - 200px);
    max-width: 900px;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-around;
    top: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 768px) {
        flex-direction: column;
        height: 100%;
        // align-items: center;
    }
}

.gallery__rectangle-item {

    @media (max-width: 768px) {
        height: 30%;
    }
}

.nav-gallery-inner 

img {

    @media (max-width: 768px) {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        object-position: center;
        transition: transform 0.3s ease;
    }
}

h4 {
    font-size: 1.5rem!important;

    @media (max-width: 768px) {
        // font-size: 1.2rem;
    }
}



.container {

}