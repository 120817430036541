@import "../../../assets/scss/variabls";
@import "../../../assets/scss/common";

.overlay {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Black color with 50% opacity */
  pointer-events: none; /* Ensure overlay does not block image interactions */

}

.cta-section {
  position: relative;
  width: 300px;
  overflow: hidden;
  padding-right: calc(var(--bs-gutter-x)* 0.5);
  padding-left: calc(var(--bs-gutter-x)* 0.5);
  margin-bottom: 80px;
}

.cta-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  color: white;
  width: 66%;
  vertical-align: middle;
  justify-content: center;
  text-align: center;
}



.cta-content {
  h1 {
    font-size: clamp(1rem, 1.5vw + 1rem, 2.5rem);
    margin-bottom: 10px;
    font-family: $heading-font;
    color: Inherit;
    text-align: center;
  }

  p {
    
    background-color: transparent;
    color: #333333;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    // font-size: clamp(1rem, 1.5vw + 1rem, 1.0rem);
    margin-bottom: 20px;
    font-family: $body-font;
    color: inherit;
  }

  .btn {
    padding: 10px 20px;
    border: 1px solid $heading-color;
    text-decoration: none;
    color: inherit;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    position: relative;

    &-light {
      background-color: transparent;
      color: white !important;
      border-color: white;

      &:hover {
        background-color: black;
        color: white;
        border-color: $heading-color;
      }
    }

    &-dark {
      background-color: $heading-color;
      color: white;

      &:hover {
        background-color: black;
        color: $heading-color;
        border-color: white;
      }
    }

    &:hover {
      background-color: black;
      color: $heading-color;
      border-color: white;
    }
  }
}

.cta-image {
  width: 100%;
  height: 750px;
  background-size: cover;
  background-position: center;
}
