.light-footer {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 60%); // From transparent to white by 50%
}

.dark-footer {
    background: linear-gradient(to bottom, rgba(85, 85, 85, 0) 0%, rgba(85, 85, 85, 1) 50%); // From transparent to #555555 by 50%
}

.footer-section {
    padding-bottom: 10px !important;
}
