/*----------------------------------------*/
/*  10. Team CSS
/*----------------------------------------*/

/*-- Team Item --*/
.team-item {}

/*-- Team Inner --*/
.team-inner {
    position: relative;
    width: 100%; /* Allow the container to be responsive */
    padding-top: 177.78%; /* 1080 / 1920 * 100% to maintain the aspect ratio */
    overflow: hidden;

    /*-- Image --*/
    & img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        object-position: center;
        transition: transform 0.3s ease; /* Add transition for smooth zoom */
    }

    /*-- Content --*/
    & .team-content {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        opacity: 1;
        visibility: visible;
        background-color: transparent;
        text-align: center;
        padding: 40px 20px;
        transition: all 0.3s ease 0s;
        transform: translateY(-50%); /* Ensure the content is vertically centered */
        & h4 {
            font-size: 32px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-bottom: 17px;
        }
        & span {
            display: block;
            width: 200px;
            font-size: 14px;
            line-height: 31px;
            text-transform: capitalize;
            letter-spacing: 1px;
            border-top: 1px solid $body-color;
            border-bottom: 1px solid $body-color;
            margin: auto;
        }
        
        /*-- Team Social --*/
        & .team-social {
            margin-top: 15px;
            display: flex;
            flex: 1 0 100%;
            width: 100%;
            justify-content: center;
            & a {
                margin: 0 12px;
                & i {}
                &:hover {
                    color: $heading-color;
                }
            }
        }
    }
    
    &:hover {
        & img {
            transform: translate(-50%, -50%) scale(1.1); /* Slight zoom effect */
        }
        & .team-content {
            opacity: 1;
            visibility: visible;
        }
    }
}
