@import "../../../assets/scss/variabls";
@import "../../../assets/scss/common";

.quote-section-left {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: left;
    margin-top: 40px;
    margin-bottom: 40px;

    .content-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        max-width: 1200px;

        @media (max-width: 768px) {
            flex-direction: column;
            text-align: center;
        }
    }

    .text-content, .image-content {
        width: 100%; /* Ensure full width on smaller screens */

        @media (min-width: 769px) {
            &:nth-child(1) {
                flex: 0 0 40%; /* 40% width for text content */
            }

            &:nth-child(2) {
                flex: 0 0 60%; /* 60% width for image content */
            }
        }
    }

    .text-content {
        margin-right: 20px;
        margin-bottom: 40px;
        text-align: center;

        @media (max-width: 768px) {
            margin-right: 0;
        }

        h1 {
            font-size: 2em;
            font-family: $heading-font;
            margin-bottom: 40px;
            margin-top: 40px;
            color: inherit;
        }

        p {
            font-size: 1em;
            max-width: 600px;
            margin-left: auto;
            margin-right: auto;
            font-family: $body-font;
            color: inherit;
        }
    }

    .image-content {
        img {
            box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
            max-width: 100%;
            height: auto;
            border-radius: 15px;
        }
    }

    .button-wrapper {
        display: flex;
        justify-content: center;
        gap: 10px;

        .btn {
            padding: 10px 20px;
            border: 1px solid $heading-color;
            text-decoration: none;
            color: $heading-color;
            border-radius: 5px;
            transition: background-color 0.3s, color 0.3s, border-color 0.3s;
        }

        .btn-light {
        }

        .btn-light:hover {
            background-color: $heading-color;
            color: white;
            border-color: $heading-color;
        }
    }
}
