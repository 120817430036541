.schedule-container {
  padding: 10px 0;
  max-width: 800px;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}

.week-header {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

/* Responsive adjustments for smaller screens */
@media (min-width: 300px) {
  .week-header .btn {
    padding: 5px 10px; /* Smaller padding */
    font-size: 12px; /* Smaller font size */
    flex-grow: 1; /* Allows buttons to fill the space evenly */
  }

  .week-header {
    font-size: 12px; /* Smaller overall font size to maintain layout */
  }
}

.schedule-day {
  text-align: center;
  margin-top: 40px;
  width: 100%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}

.weekDayText, .label {
  color: gray;
  font-size: 14px; /* Standardized font size */
  text-align: center;
}

.selectedLabel {
  color: white;
}

.weekDayItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.classTime, .className, .classInstructor {
  flex: 1;
  margin-right: 10px; /* Consistent right margin */
}

.classInstructor {
  margin-right: 0; /* No right margin on the last element */
}

.classItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.dark-version h1, .dark-version h2, .dark-version h3, .dark-version h4, .dark-version h5, .dark-version h6 {
  color: white;
}
